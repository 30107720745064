import '../App.css';
import React, { Component } from 'react';
import Story from "../components/story";
import Ticker from '../components/ticker';
import Ticker2 from '../components/ticker2';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Footer from '../components/footer';
import logo from '../assets/logo.png';
import navbar from '../assets/navigation-bar.png';
import wrong from '../assets/wrong.png';
import About from '../components/about';
import Introduce from '../components/introduce';
import GalleryLaunchApp from '../components/galleryLaunchApp';
import FAQ from '../components/faq';
import nft1 from '../assets/1.jpg';
import nft2 from '../assets/2.jpg';
import nft3 from '../assets/3.jpg';
import nft4 from '../assets/4.jpg';
import nft5 from '../assets/5.jpg';
import nft6 from '../assets/6.jpg';
import nft7 from '../assets/7.jpg';
import nft8 from '../assets/8.jpg';
import basketBall from '../assets/basketball.png';
import mm from '../assets/MetaMask_Fox.svg.png';
import wc from '../assets/wc.png';
import $ from "jquery";

var Scroll = require('react-scroll');

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

$(window).on('load', function () {
	setTimeout(function () { // allowing 3 secs to fade out loader
		$('.page-loader').fadeOut('slow');
	}, 3500);
});


let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let totalSupplyNFT2;
let maxMintNFTs;
let onlyLeft;
let owner;
let publicSale;
let user_input;
let wMintAmount;
let myTokens = [];
let myTokens2 = [];
let adminWhitelistAddresses = [];
let adminPanel = 0;
let adminWhitelistAddresses2 = [];
let maxTokensToBuy = "";
let testingvalue;
let wlMint;
let FinalResult;
let wlMsg = "Whitelist Mint";
let public_sale_cost;
let presalesale_cost;
let MAX_PER_WALLET;
let public_mint_status = false;
let token_contract;
let free_mint_status;
let freeMintCost;
let freeMintPerWallet;
let tokenEligibleBalance;
let freeMintLimit;
let tokenBalance;
let freeMinted;

$(function () {
	const scrollEffect = $('.conT2Main');
	const in9 = $('#in9');
	const in10 = $('#in10');
	const in11 = $('#in11');
	const in12 = $('#in12');
	const in13 = $('#in13');
	const in14 = $('#in14');
	const in15 = $('#in15');
	const in16 = $('#in16');


	$(window).on('scroll', function () {
		const containerTop = scrollEffect.offset().top;
		const containerBottom = containerTop + scrollEffect.outerHeight();
		const windowTop = $(window).scrollTop();
		const windowBottom = windowTop + $(window).height();

		if (windowBottom > containerTop) {
			in9.addClass('in9');
			in10.addClass('in10');
			in11.addClass('in11');
			in12.addClass('in12');
			in13.addClass('in13');
			in14.addClass('in14');
			in15.addClass('in15');
			in16.addClass('in16');

		} else {
			in9.removeClass('in9');
			in10.removeClass('in10');
			in11.removeClass('in11');
			in12.removeClass('in12');
			in13.removeClass('in13');
			in14.removeClass('in14');
			in15.removeClass('in15');
			in16.removeClass('in16');
		}


		console.log("containerTop :" + containerTop);
		console.log("containerBottom :" + containerBottom);
		console.log("windowTop :" + windowTop);
		console.log("windowBottom :" + windowBottom);


	});
});

const ABI = [
	{
		"inputs": [
			{
				"internalType": "address[]",
				"name": "receiver",
				"type": "address[]"
			},
			{
				"internalType": "uint256[]",
				"name": "quantity",
				"type": "uint256[]"
			}
		],
		"name": "airdrop",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initNotRevealedUri",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "ApprovalCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalToCurrentOwner",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "ApproveToCaller",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "BalanceQueryForZeroAddress",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "freeMint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "MintToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintZeroQuantity",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "OperatorNotAllowed",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TokenIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferFromIncorrectOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToNonERC721ReceiverImplementer",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "URIQueryForNonexistentToken",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"name": "setContractURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_freeMintCost",
				"type": "uint256"
			}
		],
		"name": "setFreeMintCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_freeMintLimit",
				"type": "uint256"
			}
		],
		"name": "setFreeMintLimit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_freeMintPerWallet",
				"type": "uint256"
			}
		],
		"name": "setFreeMintPerWallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_max_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setMax_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_MAX_SUPPLY",
				"type": "uint256"
			}
		],
		"name": "setMAX_SUPPLY",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_notRevealedURI",
				"type": "string"
			}
		],
		"name": "setNotRevealedURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_publicMintLimit",
				"type": "uint256"
			}
		],
		"name": "setPublicMintLimit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_publicSaleCost",
				"type": "uint256"
			}
		],
		"name": "setPublicSaleCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_receiver",
				"type": "address"
			},
			{
				"internalType": "uint96",
				"name": "_royaltyFeesInBips",
				"type": "uint96"
			}
		],
		"name": "setRoyaltyInfo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_tokenContract",
				"type": "address"
			}
		],
		"name": "setTokenContract",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenDecimals",
				"type": "uint256"
			}
		],
		"name": "setTokenDecimals",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenEligibleBalance",
				"type": "uint256"
			}
		],
		"name": "setTokenEligibleBalance",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_free_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_public_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleReveal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "contractURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "free_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "freeMintCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "freeMinted",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "freeMintLimit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "freeMintPerWallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "freeMintQuantity",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getBaseURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "max_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "MAX_SUPPLY",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "notRevealedUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "OPERATOR_FILTER_REGISTRY",
		"outputs": [
			{
				"internalType": "contract IOperatorFilterRegistry",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "public_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "publicMinted",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicMintLimit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicMintQuantity",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "royaltyInfo",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "token",
		"outputs": [
			{
				"internalType": "contract IERC20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "token_Contract",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "tokenDecimals",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "tokenEligibleBalance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenOfOwnerByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const address = "0x61614780a9Bb203c32ed76a205a02AAF7CA8e909";

const token_ABI = [
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "burn",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "burnFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "subtractedValue",
				"type": "uint256"
			}
		],
		"name": "decreaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "addedValue",
				"type": "uint256"
			}
		],
		"name": "increaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "pause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Paused",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transfer",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "unpause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Unpaused",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			}
		],
		"name": "allowance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimals",
		"outputs": [
			{
				"internalType": "uint8",
				"name": "",
				"type": "uint8"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const token_address = "0x8E8eD28f157558A73c6f4F21f26538AA2C128727";

let contract;

class Home extends Component {

	state = {
		walletAddress: "",
		totalSupply: "",
		currentPrice: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		statusError: false,
		statusLoading: false,
		success: false,
		statusErrorFree: false,
		statusLoadingFree: false,
		successFree: false,
		nftMintingAmount: 1,
		totalValue: "",
		presaleValue: "0",
		maxmint: '',
		_adminPanel: 0,
		_adminWhitelistAddresses: [],
		_adminWhitelistAddresses2: [],
		_maxTokensToBuy: "",
		_testingValue: '',
		_wlMint: '',
		_FinalResult: '',
		_wlMsg: 'Whitelist Mint',
		_public_sale_cost: '',
		_presalesale_cost: '',
		_MAX_PER_WALLET: '',
		_public_mint_status: '',
		_presale_status: '',
		_cost: '',
		_mintStatus: false,
		gallery: '',
		_navbarOpen: 0,
		_free_mint_status: '',
		_freeMintCost: '',
		_dirnamefreeMintPerWallet: '',
		_tokenEligibleBalance: '',
		_freeMintLimit: '',
		_tokenBalance: '',
		_freeMinted: ''
	}

	closeNav = () => {
		const navBar = document.querySelector(".littleNav");
		navBar.classList.add("hidden");
	}

	closeNav = async event => {
		event.preventDefault();

		this.setState({ _navbarOpen: 0 });
	}

	async componentDidMount() {


		if (localStorage?.getItem('isWalletConnected') === 'true') {

			if (typeof window.ethereum !== 'undefined') {
				console.log('MetaMask is installed!');


				try {

					const web3mm = new Web3(window.ethereum);

					// Request access to user accounts
					await window.ethereum.request({ method: 'eth_requestAccounts' });
					console.log("metamask on PC")
					// Continue with your DApp logic
					const accounts = await web3mm.eth.getAccounts();
					const account = accounts[0];

					// Fetch data from contracts, etc.
					// ...
					// Fetch data from contracts
					contract = new web3mm.eth.Contract(ABI, address);
					token_contract = new web3mm.eth.Contract(token_ABI, token_address);

					// Update your state with the wallet address
					this.setState({ walletAddress: account });

					// Rest of your MetaMask-specific logic can go here
					// ...

					const chainId = 5;

					if (window.ethereum.networkVersion !== chainId) {
						try {
							await window.ethereum.request({
								method: 'wallet_switchEthereumChain',
								params: [{ chainId: web3mm.utils.toHex(chainId) }],
							});
						} catch (err) {
							// This error code indicates that the chain has not been added to MetaMask.
							if (err.code === 4902) {
								await window.ethereum.request({
									method: 'wallet_addEthereumChain',
									params: [
										{
											/*chainName: 'zkSync alpha testnet',
											chainId: web3mm.utils.toHex(chainId),
											nativeCurrency: { name: 'zkSync alpha testnet', decimals: 18, symbol: 'ETH' },
											rpcUrls: ['https://zksync2-testnet.zkscan.io/'],*/

											chainName: 'Goerli Test Network',
											chainId: web3mm.utils.toHex(chainId),
											nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
											rpcUrls: ['https://goerli.infura.io/v3/'],

											/*chainName: 'Rinkeby Test Network',
											chainId: web3mm.utils.toHex(chainId),
											nativeCurrency: { name: 'RinkebyETH', decimals: 18, symbol: 'ETH' },
											rpcUrls: ['https://rinkeby.infura.io/v3/'],*/
											/*chainName: 'Mumbai Testnet',
											chainId: web3mm.utils.toHex(chainId),
											nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
											rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],*/
										},
									],
								});
							}
						}
					}



					try {
						localStorage.setItem('isWalletConnected', true);
					} catch (ex) {
						console.log(ex)
					}

					totalSupplyNFT = await contract.methods.totalSupply().call();
					this.setState({ totalSupply: totalSupplyNFT });
					console.log("Total Supply:" + totalSupplyNFT);

					publicSale = await contract.methods.balanceOf(account).call();
					this.setState({ myNFTWallet: publicSale });

					public_sale_cost = await contract.methods.publicSaleCost().call();
					this.setState({ _public_sale_cost: public_sale_cost });
					console.log("public_sale_cost :" + public_sale_cost);
					this.setState({ _cost: public_sale_cost });
					this.setState({ _mintStatus: false });

					MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
					this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
					console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

					freeMintPerWallet = await contract.methods.freeMintPerWallet().call();
					this.setState({ _freeMintPerWallet: freeMintPerWallet });

					tokenEligibleBalance = await contract.methods.tokenEligibleBalance().call();
					this.setState({ _tokenEligibleBalance: tokenEligibleBalance });
					console.log("tokenEligibleBalance :" + tokenEligibleBalance);

					tokenBalance = await token_contract.methods.balanceOf(account).call();
					this.setState({ _tokenBalance: tokenBalance });
					console.log("tokenBalance :" + tokenBalance);

					freeMintCost = await contract.methods.freeMintCost().call();
					this.setState({ _freeMintCost: freeMintCost });

					freeMintLimit = await contract.methods.freeMintQuantity().call();
					this.setState({ _freeMintLimit: freeMintLimit });

					freeMinted = await contract.methods.freeMinted(account).call();
					this.setState({ _freeMinted: freeMinted });
					console.log("_freeMinted :" + freeMinted);

					public_mint_status = await contract.methods.public_mint_status().call();
					this.setState({ _public_mint_status: public_mint_status });
					console.log("public_mint_status :" + public_mint_status);

					free_mint_status = await contract.methods.free_mint_status().call();
					this.setState({ _free_mint_status: free_mint_status });
					console.log("free_mint_status :" + free_mint_status);

					owner = await contract.methods.owner().call();
					console.log("Owner" + owner);
					//...........................................................................//
					if (owner == account) {
						console.log("owner : " + owner)
						onlyLeft = 2000 - totalSupplyNFT;

						if (mintAmount > onlyLeft) {
							mintAmount = onlyLeft;
						}

						valueOfNFTs = mintAmount * 0;
						wMintAmount = mintAmount;


						this.setState({ nftMintingAmount: mintAmount });

						this.setState({ totalValue: valueOfNFTs });
					} else {
						mintAmount = 1;

						if (totalSupplyNFT == 2000) {

							onlyLeft = 2000 - totalSupplyNFT;
							mintAmount = onlyLeft;
							this.setState({ msg: "SOLD OUT" });

						} else {
							mintAmount = 1;
							onlyLeft = MAX_PER_WALLET - publicSale;

							if (mintAmount > onlyLeft) {
								mintAmount = onlyLeft;
							}
							//mintAmount = onlyLeft;

							valueOfNFTs = mintAmount * this.state._cost;
							wMintAmount = mintAmount;


							this.setState({ nftMintingAmount: mintAmount });

							this.setState({ totalValue: valueOfNFTs });
						}
					}
					///.................................///



					//...........................................................................//


				} catch (err) {
					console.log("err: " + err);

				}

				//})();

				//.....................................................................//

				// Legacy providers may only have ethereum.sendAsync



			}
		}

	}

	onSubmitMinus = async event => {
		event.preventDefault();

		mintAmount = mintAmount - 1;

		if (mintAmount < 1) {
			mintAmount = 1
		}

		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 2000 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;



			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {
			if (totalSupplyNFT < 2000) {

				onlyLeft = MAX_PER_WALLET - publicSale;

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;


				this.setState({ nftMintingAmount: mintAmount });
				console.log("nftMintingAmount - hitting - :" + this.state.nftMintingAmount);

				this.setState({ totalValue: valueOfNFTs });
			}
		}

	}

	onSubmitPlus = async event => {
		event.preventDefault();

		//,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
		mintAmount = mintAmount + 1;

		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 1000 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;



			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {

			if (totalSupplyNFT < 2000) {

				onlyLeft = MAX_PER_WALLET - publicSale;
				console.log(onlyLeft);

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;

				this.setState({ nftMintingAmount: mintAmount });
				console.log("nftMintingAmount - hitting + :" + this.state.nftMintingAmount);

				this.setState({ totalValue: valueOfNFTs });
			}
		}

	}

	onSubmit2 = async event => {
		event.preventDefault();

		const web3mm = new Web3(window.ethereum);

		// Request access to user accounts
		await window.ethereum.request({ method: 'eth_requestAccounts' });
		console.log("metamask on PC")
		// Continue with your DApp logic
		const accounts = await web3mm.eth.getAccounts();
		const account = accounts[0];

		// Fetch data from contracts, etc.
		// ...
		// Fetch data from contracts
		contract = new web3mm.eth.Contract(ABI, address);
		token_contract = new web3mm.eth.Contract(token_ABI, token_address);

		// Update your state with the wallet address
		this.setState({ walletAddress: account });

		// Rest of your MetaMask-specific logic can go here

		console.log(this.state.walletAddress);

		try {
			let owner = await contract.methods.owner().call();


			if (account != owner) {


				try {

					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);

					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1).send({ gasLimit: 385000, from: account, value: this.state.totalValue * 1 });

					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);
					await new Promise(resolve => setTimeout(resolve, 5000));
					window.location.reload(true);


				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);


				}
			} else {

				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);


					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1).send({ gasLimit: 385000, from: account, value: this.state.totalValue * 0 });


					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);
					await new Promise(resolve => setTimeout(resolve, 5000));
					window.location.reload(true);


				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);

				}

			}
		} catch (err) {

			console.log(err);

		}
	}

	freeMint = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);

		try {
			let owner = await contract.methods.owner().call();


			if (account != owner) {


				try {

					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);

					this.setState({ statusErrorFree: false, statusLoadingFree: true });
					await contract.methods.freeMint(1).send({ gasLimit: 385000, from: account, value: this.state.totalValue * 0 });

					this.setState({ statusLoadingFree: false, successFree: true });
					console.log("Mint Amount :" + this.state.mintAmount);


				} catch (err) {
					this.setState({ errorMassageFree: "ERROR : " + err.message, statusLoadingFree: false, successFree: false, statusErrorFree: true });
					console.log(err);


				}
			} else {

				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);


					this.setState({ statusErrorFree: false, statusLoadingFree: true });
					await contract.methods.freeMint(1).send({ gasLimit: 385000, from: account, value: this.state.totalValue * 0 });


					this.setState({ statusLoadingFree: false, successFree: true });
					console.log("Mint Amount :" + this.state.mintAmount);


				} catch (err) {
					this.setState({ errorMassageFree: "ERROR : " + err.message, statusLoadingFree: false, successFree: false, statusErrorFree: true });
					console.log(err);

				}

			}
		} catch (err) {

			console.log(err);

		}
	}

	walletConnect = async event => {
		event.preventDefault();

		if (typeof window.ethereum !== 'undefined') {
			console.log('MetaMask is installed!');

			try {

				const web3mm = new Web3(window.ethereum);

				// Request access to user accounts
				await window.ethereum.request({ method: 'eth_requestAccounts' });
				console.log("metamask on PC")
				// Continue with your DApp logic
				const accounts = await web3mm.eth.getAccounts();
				const account = accounts[0];

				// Fetch data from contracts, etc.
				// ...
				// Fetch data from contracts
				contract = new web3mm.eth.Contract(ABI, address);
				token_contract = new web3mm.eth.Contract(token_ABI, token_address);

				// Update your state with the wallet address
				this.setState({ walletAddress: account });

				// Rest of your MetaMask-specific logic can go here
				// ...
				const chainId = 5;

				if (window.ethereum.networkVersion !== chainId) {
					try {
						await window.ethereum.request({
							method: 'wallet_switchEthereumChain',
							params: [{ chainId: web3mm.utils.toHex(chainId) }],
						});
					} catch (err) {
						// This error code indicates that the chain has not been added to MetaMask.
						if (err.code === 4902) {
							await window.ethereum.request({
								method: 'wallet_addEthereumChain',
								params: [
									{
										/*chainName: 'zkSync alpha testnet',
										chainId: web3mm.utils.toHex(chainId),
										nativeCurrency: { name: 'zkSync alpha testnet', decimals: 18, symbol: 'ETH' },
										rpcUrls: ['https://zksync2-testnet.zkscan.io/'],*/

										chainName: 'Goerli Test Network',
										chainId: web3mm.utils.toHex(chainId),
										nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
										rpcUrls: ['https://goerli.infura.io/v3/'],

										/*chainName: 'Rinkeby Test Network',
										chainId: web3mm.utils.toHex(chainId),
										nativeCurrency: { name: 'RinkebyETH', decimals: 18, symbol: 'ETH' },
										rpcUrls: ['https://rinkeby.infura.io/v3/'],*/
										/*chainName: 'Mumbai Testnet',
										chainId: web3mm.utils.toHex(chainId),
										nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
										rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],*/
									},
								],
							});
						}
					}
				}


				try {
					localStorage.setItem('isWalletConnected', true);
				} catch (ex) {
					console.log(ex)
				}


				try {

					try {
						localStorage.setItem('isWalletConnected', true);
					} catch (ex) {
						console.log(ex)
					}

					totalSupplyNFT = await contract.methods.totalSupply().call();
					this.setState({ totalSupply: totalSupplyNFT });
					console.log("Total Supply:" + totalSupplyNFT);

					publicSale = await contract.methods.balanceOf(account).call();
					this.setState({ myNFTWallet: publicSale });

					public_sale_cost = await contract.methods.publicSaleCost().call();
					this.setState({ _public_sale_cost: public_sale_cost });
					console.log("public_sale_cost :" + public_sale_cost);
					this.setState({ _cost: public_sale_cost });
					this.setState({ _mintStatus: false });

					MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
					this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
					console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

					freeMintPerWallet = await contract.methods.freeMintPerWallet().call();
					this.setState({ _freeMintPerWallet: freeMintPerWallet });

					tokenEligibleBalance = await contract.methods.tokenEligibleBalance().call();
					this.setState({ _tokenEligibleBalance: tokenEligibleBalance });
					console.log("tokenEligibleBalance :" + tokenEligibleBalance);

					tokenBalance = await token_contract.methods.balanceOf(account).call();
					this.setState({ _tokenBalance: tokenBalance });
					console.log("tokenBalance :" + tokenBalance);

					freeMintCost = await contract.methods.freeMintCost().call();
					this.setState({ _freeMintCost: freeMintCost });

					freeMintLimit = await contract.methods.freeMintQuantity().call();
					this.setState({ _freeMintLimit: freeMintLimit });

					freeMinted = await contract.methods.freeMinted(account).call();
					this.setState({ _freeMinted: freeMinted });
					console.log("_freeMinted :" + freeMinted);

					public_mint_status = await contract.methods.public_mint_status().call();
					this.setState({ _public_mint_status: public_mint_status });
					console.log("public_mint_status :" + public_mint_status);

					free_mint_status = await contract.methods.free_mint_status().call();
					this.setState({ _free_mint_status: free_mint_status });
					console.log("free_mint_status :" + free_mint_status);

					owner = await contract.methods.owner().call();
					console.log("Owner" + owner);
					//...........................................................................//
					if (owner == account) {
						console.log("owner : " + owner)
						onlyLeft = 2000 - totalSupplyNFT;

						if (mintAmount > onlyLeft) {
							mintAmount = onlyLeft;
						}

						valueOfNFTs = mintAmount * 0;
						wMintAmount = mintAmount;


						this.setState({ nftMintingAmount: mintAmount });

						this.setState({ totalValue: valueOfNFTs });
					} else {
						mintAmount = 1;

						if (totalSupplyNFT == 2000) {

							onlyLeft = 2000 - totalSupplyNFT;
							mintAmount = onlyLeft;
							this.setState({ msg: "SOLD OUT" });

						} else {
							mintAmount = 1;
							onlyLeft = MAX_PER_WALLET - publicSale;

							if (mintAmount > onlyLeft) {
								mintAmount = onlyLeft;
							}
							//mintAmount = onlyLeft;

							valueOfNFTs = mintAmount * this.state._cost;
							wMintAmount = mintAmount;


							this.setState({ nftMintingAmount: mintAmount });

							this.setState({ totalValue: valueOfNFTs });
						}
					}
					///.................................///



					//...........................................................................//

				} catch (err) {
					console.log("err: " + err);

				}

			} catch (err) {
				console.log("err: " + err);

			}

			//})();

			//.....................................................................//

			// Legacy providers may only have ethereum.sendAsync



		}

	}

	mm = async event => {
		event.preventDefault();

		//const userAgent = window.navigator.userAgent;
		//const isMobile = /Android|iPhone|iPad|iPod|Windows Phone|BlackBerry|Mobile|webOS|Opera Mini|IEMobile|Mobile Safari|SamsungBrowser|Nokia/.test(userAgent);
		//const isMetaMaskBrowser = window.ethereum && window.ethereum.isMetaMask;

		//if (isMobile && isMetaMaskBrowser) {

		if (typeof window.ethereum !== 'undefined') {
			console.log('MetaMask is installed!');

			try {

				const web3mm = new Web3(window.ethereum);

				// Request access to user accounts
				await window.ethereum.request({ method: 'eth_requestAccounts' });
				console.log("metamask on PC")
				// Continue with your DApp logic
				const accounts = await web3mm.eth.getAccounts();
				const account = accounts[0];

				// Fetch data from contracts, etc.
				// ...
				// Fetch data from contracts
				const contract = new web3mm.eth.Contract(ABI, address);
				const token_contract = new web3mm.eth.Contract(token_ABI, token_address);


				// Update your state with the wallet address
				this.setState({ walletAddress: account });

				// Rest of your MetaMask-specific logic can go here
				// ...

				const chainId = 5;

				if (window.ethereum.networkVersion !== chainId) {
					try {
						await window.ethereum.request({
							method: 'wallet_switchEthereumChain',
							params: [{ chainId: web3mm.utils.toHex(chainId) }],
						});
					} catch (err) {
						// This error code indicates that the chain has not been added to MetaMask.
						if (err.code === 4902) {

							await window.ethereum.request({
								method: 'wallet_addEthereumChain',
								params: [
									{
										/*chainName: 'Smart Chain - Testnethttps://testnet.bscscan.com',
										chainId: web3.utils.toHex(chainId),
										nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
										rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],*/

										/*chainName: 'Base Goerli',
										chainId: web3.utils.toHex(chainId),
										nativeCurrency: { name: 'Base Goerli', decimals: 18, symbol: 'ETH' },
										rpcUrls: ['https://goerli.base.org'],*/

										chainName: 'Goerli  Network',
										chainId: web3mm.utils.toHex(chainId),
										nativeCurrency: { name: 'Goerli  Network', decimals: 18, symbol: 'GoerliETH' },
										rpcUrls: ['https://goerli.infura.io/v3/'],

										/*chainName: 'BNB Smart Chain Mainnet',
										chainId: web3mm.utils.toHex(chainId),
										nativeCurrency: { name: 'BNB Smart Chain Mainnet', decimals: 18, symbol: 'BNB' },
										rpcUrls: ['https://bsc-dataseed.binance.org'],*/

									},
								],
							});
						}
					}
				}
				try {


					totalSupplyNFT = await contract.methods.totalSupply().call();
					this.setState({ totalSupply: totalSupplyNFT });
					console.log("Total Supply:" + totalSupplyNFT);

					publicSale = await contract.methods.balanceOf(account).call();
					this.setState({ myNFTWallet: publicSale });

					public_sale_cost = await contract.methods.publicSaleCost().call();
					this.setState({ _public_sale_cost: public_sale_cost });
					console.log("public_sale_cost :" + public_sale_cost);
					this.setState({ _cost: public_sale_cost });
					this.setState({ _mintStatus: false });

					MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
					this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
					console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

					freeMintPerWallet = await contract.methods.freeMintPerWallet().call();
					this.setState({ _freeMintPerWallet: freeMintPerWallet });

					tokenEligibleBalance = await contract.methods.tokenEligibleBalance().call();
					this.setState({ _tokenEligibleBalance: tokenEligibleBalance });
					console.log("tokenEligibleBalance :" + tokenEligibleBalance);

					/*tokenBalance = await token_contract.methods.balanceOf(account).call();
					this.setState({ _tokenBalance: tokenBalance });
					console.log("tokenBalance :" + tokenBalance);*/

					freeMintCost = await contract.methods.freeMintCost().call();
					this.setState({ _freeMintCost: freeMintCost });

					freeMintLimit = await contract.methods.freeMintQuantity().call();
					this.setState({ _freeMintLimit: freeMintLimit });

					freeMinted = await contract.methods.freeMinted(account).call();
					this.setState({ _freeMinted: freeMinted });
					console.log("_freeMinted :" + freeMinted);

					public_mint_status = await contract.methods.public_mint_status().call();
					this.setState({ _public_mint_status: public_mint_status });
					console.log("public_mint_status :" + public_mint_status);

					free_mint_status = await contract.methods.free_mint_status().call();
					this.setState({ _free_mint_status: free_mint_status });
					console.log("free_mint_status :" + free_mint_status);

					owner = await contract.methods.owner().call();
					console.log("Owner" + owner);
					//...........................................................................//
					if (owner == account) {
						console.log("owner : " + owner)
						onlyLeft = 2000 - totalSupplyNFT;

						if (mintAmount > onlyLeft) {
							mintAmount = onlyLeft;
						}

						valueOfNFTs = mintAmount * 0;
						wMintAmount = mintAmount;


						this.setState({ nftMintingAmount: mintAmount });

						this.setState({ totalValue: valueOfNFTs });
					} else {
						mintAmount = 1;

						if (totalSupplyNFT == 2000) {

							onlyLeft = 2000 - totalSupplyNFT;
							mintAmount = onlyLeft;
							this.setState({ msg: "SOLD OUT" });

						} else {
							mintAmount = 1;
							onlyLeft = MAX_PER_WALLET - publicSale;

							if (mintAmount > onlyLeft) {
								mintAmount = onlyLeft;
							}
							//mintAmount = onlyLeft;

							valueOfNFTs = mintAmount * this.state._cost;
							wMintAmount = mintAmount;


							this.setState({ nftMintingAmount: mintAmount });

							this.setState({ totalValue: valueOfNFTs });
						}
					}
					///.................................///



					//...........................................................................//

				} catch (err) {
					console.log("err: " + err);

				}

			} catch (error) {
				console.error(error);
				// Handle errors or user rejections
			}
		} else {
			window.open("https://metamask.app.link/dapp/nftstake.frogchain.io/");
		}
	};

	walletDisconnect = async event => {
		event.preventDefault();


		try {
			let walletAddressNew = '';
			this.setState({ walletAddress: walletAddressNew });
			localStorage.setItem('isWalletConnected', false);
		} catch (ex) {
			console.error(ex);
		}

		console.log("walletAddress : " + this.state.walletAddress)

		//			window.location.reload(true);

	}
	navbarOpen = async event => {
		event.preventDefault();

		this.setState({ _navbarOpen: 1 });
	}


	render() {

		return (

			<div class="allWrap">
				<div class="light">
					<div class="page-loader">
						<div class="txt">YOOPUNKS</div>
						<div class="loading-area">
							<div class="loader">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div>
					</div>
					<div class="cont">

						{this.state._navbarOpen < 1 ?
							(<div class="miniBarMain">
								<Link to="gallery" spy={true} smooth={true} duration={550}><div ><img class="logo" src={logo} /></div></Link>

								<div class="navbarMain"><img class="navbar" onClick={this.navbarOpen} src={navbar} /></div>
							</div>) :
							(<div class="miniBarMain">
								<Link to="gallery" spy={true} smooth={true} duration={550}><div ><img class="logo" src={logo} /></div></Link>

								<div class="navbarMain"><img class="navbar" onClick={this.closeNav} src={wrong} /></div>
							</div>)}
						<Link to="gallery" spy={true} smooth={true} duration={550}>
							<div class="headers">

								<div class="h1">

									<div class="topLogo">

										<div ><img class="logoMobile" src={logo} /></div>

										<div class="iconsMob">
											<div class="connect2Mobile">
												<button class="connectBtn" >Connect Wallet</button>
											</div>
										</div>
									</div>

									<div class="logoDiv"><img class="logo" src={logo} /></div>

									<div class="connect">
										<div ><Link activeClass="" id="fontSize" to="mint" spy={true} smooth={true} duration={550}>Minting</Link></div>
										<div ><Link activeClass="" id="fontSize" spy={true} smooth={true} duration={550}>Gallery</Link></div>
										<div ><Link activeClass="" id="fontSize" spy={true} smooth={true} duration={550}>Buy on opensea</Link></div>

									</div>

									<div class="right">
										{this.state.walletAddress === '' ?
											(<button onClick={this.walletConnect} class="connectBtn">
												Connect
											</button>
											) : (
												<button onClick={this.walletDisconnect} class="connectBtn">
													{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}

												</button>
											)}
									</div>

								</div>

							</div>

							{this.state._navbarOpen > 0 ?
								(<div class="littleNav">
									<div ><Link activeClass="" id="fontSize" to="mint" spy={true} smooth={true} duration={550}>Minting</Link></div>
									<div ><Link activeClass="" id="fontSize" spy={true} smooth={true} duration={550}>Gallery</Link></div>
									<div ><Link activeClass="" id="fontSize" spy={true} smooth={true} duration={550}>Buy on opensea</Link></div>
									<div class="connect2btns">

										{this.state.walletAddress === '' ?
											(<form onClick={this.walletConnect} class="connect2-2-Mob">
												<img onClick={this.walletConnect} class="mm" src={mm} />
											</form>) : (
												<button onClick={this.walletDisconnect} class="connect2-2-Mob">
													{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}

												</button>
											)}
									</div>
								</div>) : null}
						</Link>


					</div>

					<Element name="story">
						<Introduce />
					</Element>

					<Element name="story">
						<Story />
					</Element>

					<Element name="mint">
						<div class="boxWrap2Mint">

							<div class="conT2Main">
								<div class="conT2">
									<span id="in9">M</span>
									<span id="in10">I</span>
									<span id="in11">N</span>
									<span id="in12">T </span>
									<span>&nbsp;</span>
									<span id="in13">A</span>
									<span id="in14">R</span>
									<span id="in15">E</span>
									<span id="in16">A</span>
								</div>
							</div>

							{this.state.walletAddress === '' ?

								(<div class="mintDiv">

									<div class="slideImg">
										<div class="animation-container">
											<img class="image" src={nft1} alt="Image 1" />
											<img class="image" src={nft2} alt="Image 2" />
											<img class="image" src={nft3} alt="Image 3" />
											<img class="image" src={nft4} alt="Image 4" />
											<img class="image" src={nft5} alt="Image 5" />
											<img class="image" src={nft5} alt="Image 1" />
											<img class="image" src={nft6} alt="Image 1" />
											<img class="image" src={nft7} alt="Image 2" />
											<img class="image" src={nft8} alt="Image 3" />
											<img class="image" src={nft8} alt="Image 4" />
											<img class="image" src={nft5} alt="Image 5" />
											<img class="image" src={nft5} alt="Image 5" />
										</div>

									</div>
									<div class="mintBox">

										<div class="mintBox2">
											<div class="mintCon1">Be part of the creative revolution and mint your own unique NFTs</div>

											<div class="basketBallDiv"><img class="basketBall" src={basketBall}></img></div>
											<button class="mintBtn" onClick={this.walletConnect}>CONNECT WALLET</button>
										</div>
									</div>

									<div class="slideImg2">
										<div class="animation-container">
											<img class="image" src={nft1} alt="Image 1" />
											<img class="image" src={nft2} alt="Image 2" />
											<img class="image" src={nft3} alt="Image 3" />
											<img class="image" src={nft4} alt="Image 4" />
											<img class="image" src={nft5} alt="Image 5" />
											<img class="image" src={nft5} alt="Image 1" />
											<img class="image" src={nft6} alt="Image 1" />
											<img class="image" src={nft7} alt="Image 2" />
											<img class="image" src={nft8} alt="Image 3" />
											<img class="image" src={nft8} alt="Image 4" />
											<img class="image" src={nft5} alt="Image 5" />
											<img class="image" src={nft5} alt="Image 5" />
										</div>

									</div>

								</div>) : (
									<div class="mintDiv">
										{(Number(this.state._tokenBalance) > Number(this.state._tokenEligibleBalance)) && (Number(this.state._freeMinted) < 1) ? (
											<div class="mintBox--1">
												<div class="mintBox2--2">
													<div class="totalSupply2">FREE MINTED</div>
													<div class="totalSupply">{this.state._freeMintLimit} / 500</div>
													{this.state._MAX_PER_WALLET == this.state.myNFTWallet ?

														(<div class="price"><div>Limit Reached!</div></div>) :
														(<div class="price"><div>🎉Congratulations!🎉 <br />You are rewarded for being in our community</div></div>)}
													<div class="minting_count_button">
													</div>

													<form onSubmit={this.freeMint}>
														<button class="mintBtn--2" type="submit">
															FREE MINT</button>
													</form>

													<div>

														{this.state.statusErrorFree ? (
															<div class="errorMessage">
																<div >Sorry, something went wrong please try again later</div>
															</div>)
															: null}

														{this.state.statusLoadingFree ? (
															<div class="loadingContainer">
																<div>
																	<div class="loadingText">Minting your NFT</div>
																</div>
															</div>)
															: null}

														{this.state.successFree ? (

															<div class="success-message">
																<h2>Successful NFT Minting!</h2>
																<p>Your NFT has been successfully minted.</p>
															</div>)
															: null}

													</div>
												</div>
											</div>
										) :
											(<div>
												<div class="slideImg2">
													<div class="animation-container">
														<img class="image" src={nft1} alt="Image 1" />
														<img class="image" src={nft2} alt="Image 2" />
														<img class="image" src={nft3} alt="Image 3" />
														<img class="image" src={nft4} alt="Image 4" />
														<img class="image" src={nft5} alt="Image 5" />
														<img class="image" src={nft5} alt="Image 1" />
														<img class="image" src={nft6} alt="Image 1" />
														<img class="image" src={nft7} alt="Image 2" />
														<img class="image" src={nft8} alt="Image 3" />
														<img class="image" src={nft8} alt="Image 4" />
														<img class="image" src={nft5} alt="Image 5" />
														<img class="image" src={nft5} alt="Image 5" />
													</div>

												</div>

												<div class="slideImg">
													<div class="animation-container">
														<img class="image" src={nft1} alt="Image 1" />
														<img class="image" src={nft2} alt="Image 2" />
														<img class="image" src={nft3} alt="Image 3" />
														<img class="image" src={nft4} alt="Image 4" />
														<img class="image" src={nft5} alt="Image 5" />
														<img class="image" src={nft5} alt="Image 1" />
														<img class="image" src={nft6} alt="Image 1" />
														<img class="image" src={nft7} alt="Image 2" />
														<img class="image" src={nft8} alt="Image 3" />
														<img class="image" src={nft8} alt="Image 4" />
														<img class="image" src={nft5} alt="Image 5" />
														<img class="image" src={nft5} alt="Image 5" />
													</div>

												</div>
											</div>)}

										<div class="mintBox">
											<div class="mintBox2">
												<div class="totalSupply2">TOTAL SUPPLY</div>
												<div class="totalSupply">{this.state.totalSupply} / 2000</div>
												{this.state._MAX_PER_WALLET == this.state.myNFTWallet ?

													(<div class="price"><div>Limit Reached!</div></div>) :
													(<div class="price"><div>NFT PRICE {this.state._cost / 1000000000000000000} Eth</div></div>)}

												<div class="minting_count_button">
													<div class="center">
														<button onClick={this.onSubmitMinus} class="btnfos-0-2">-</button>
													</div>

													<div>
														<div class="nftminter2"><span class="nftamount">{this.state.nftMintingAmount}</span></div>
													</div>

													<div class="center">
														<button onClick={this.onSubmitPlus} class="btnfos-0-2">+</button>
													</div>
												</div>

												<form onSubmit={this.onSubmit2}>
													<button class="mintBtn" type="submit">
														MINT HERE</button>
												</form>

												<div>

													{this.state.statusError ? (
														<div class="errorMessage">
															<div >Sorry, something went wrong please try again later</div>
														</div>)
														: null}

													{this.state.statusLoading ? (
														<div class="loadingContainer">
															<div>
																<div class="loadingText">Minting your NFT</div>
															</div>
														</div>)
														: null}

													{this.state.success ? (

														<div class="success-message">
															<h2>Successful NFT Minting!</h2>
															<p>Your NFT has been successfully minted.</p>
														</div>)
														: null}

												</div>
											</div>
										</div>
									</div>
								)}

						</div>

					</Element>

					<Ticker />

					<GalleryLaunchApp />

					<FAQ />

					<About />

					<Ticker2 />

					<Footer />

				</div>

			</div>)
	}
}

export default Home;
